import React, { useEffect, useState } from "react"
import { navigate } from "gatsby-link"
import queryString from "query-string"

export const ConfiguratorContext = React.createContext()

const Provider = props => {
  const [savedColors, setSavedColors] = useState([])
  const [materials, setMaterials] = useState({})
  const [shareableQueryString, setShareableQueryString] = useState("")

  useEffect(() => {
    if (typeof window !== "undefined") {
      let queryString = ""
      const entries = Object.entries(materials)

      entries.map((mat, index) => {
        queryString += `${mat[0]}=${mat[1]}`
        if (index < entries.length - 1) {
          queryString += "&"
        }
      })
      if (queryString) {
        // navigate(`?${queryString}`)
        setShareableQueryString(`?${queryString}`)
      }
    }
  }, [materials])

  useEffect(() => {
    if (typeof window !== "undefined" && shareableQueryString) {
      const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${shareableQueryString}`
      window.history.pushState({ path: shareableQueryString }, "", baseUrl)
    }
  }, [shareableQueryString])

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      console.log("window.location", window.location)
      const parameters = window.location.search
        ? queryString.parse(window.location.search)
        : ""
      const pathName = window.location.pathname
      if (parameters) {
        if (parameters.motor) {
          const currentMotor = parameters.motor
          const newMotorsIds = [
            "713", // 34 open
            "784",
            "642",
            "555",
            "772", // 37 open
            "701",
            "630",
            "543",
            "884", // 43 open
            "651",
            "813",
            "742",
            "711", // 43 solstice
            "944",
            "802",
            "873",
            "882", // 52 vitesse
            "811",
            "953",
            "724",
            "841", // 60 pied a mer
            "912",
            "758",
            "983",
          ]

          // console.log("newMotorsIds", newMotorsIds.length)

          const oldIds = [
            "763", // 34 open
            "834",
            "692",
            "605",
            "808", //  37 open
            "737",
            "666",
            "579",
            "932", // 43 open
            "699",
            "861",
            "790",
            "3", // 43 solstice
            "165",
            "94",
            "236",
            "850", // 52 vitesse
            "779",
            "921",
            "692",
            "1053", // 60 pied a mer
            "899",
            "1124",
            "982",
          ]

          // console.log("oldIds", oldIds.length)
          // console.log("parameters.motor", parameters.motor)

          if (currentMotor === oldIds[0]) {
            parameters.motor = newMotorsIds[0]
          }

          if (currentMotor === oldIds[1]) {
            parameters.motor = newMotorsIds[1]
          }

          if (currentMotor === oldIds[2]) {
            parameters.motor = newMotorsIds[2]
          }

          if (currentMotor === oldIds[3]) {
            parameters.motor = newMotorsIds[3]
          }

          if (currentMotor === oldIds[4]) {
            parameters.motor = newMotorsIds[4]
          }

          if (currentMotor === oldIds[5]) {
            parameters.motor = newMotorsIds[5]
          }

          if (currentMotor === oldIds[6]) {
            parameters.motor = newMotorsIds[6]
          }

          if (currentMotor === oldIds[7]) {
            parameters.motor = newMotorsIds[7]
          }

          if (currentMotor === oldIds[8]) {
            parameters.motor = newMotorsIds[8]
          }

          if (currentMotor === oldIds[9]) {
            parameters.motor = newMotorsIds[9]
          }

          if (currentMotor === oldIds[10]) {
            parameters.motor = newMotorsIds[10]
          }

          if (currentMotor === oldIds[11]) {
            parameters.motor = newMotorsIds[11]
          }

          if (currentMotor === oldIds[12]) {
            parameters.motor = newMotorsIds[12]
          }

          if (currentMotor === oldIds[13]) {
            parameters.motor = newMotorsIds[13]
          }

          if (currentMotor === oldIds[14]) {
            parameters.motor = newMotorsIds[14]
          }

          if (currentMotor === oldIds[15]) {
            parameters.motor = newMotorsIds[15]
          }

          if (currentMotor === oldIds[16]) {
            parameters.motor = newMotorsIds[16]
          }

          if (currentMotor === oldIds[17]) {
            parameters.motor = newMotorsIds[17]
          }

          if (currentMotor === oldIds[18]) {
            parameters.motor = newMotorsIds[18]
          }

          if (currentMotor === oldIds[19]) {
            parameters.motor = newMotorsIds[19]
          }

          if (currentMotor === oldIds[20]) {
            parameters.motor = newMotorsIds[20]
          }

          if (currentMotor === oldIds[21]) {
            parameters.motor = newMotorsIds[21]
          }

          if (currentMotor === oldIds[22]) {
            parameters.motor = newMotorsIds[22]
          }

          if (currentMotor === oldIds[23]) {
            parameters.motor = newMotorsIds[23]
          }

          if (
            !oldIds.includes(currentMotor) &&
            !newMotorsIds.includes(currentMotor)
          ) {
            if (pathName === "/boat-builder/34-open-2/") {
              parameters.motor = "713"
            }
            if (pathName === "/boat-builder/37-open-3/") {
              parameters.motor = "772"
            }
            if (pathName === "/boat-builder/43-open-2/") {
              parameters.motor = "884"
            }
            if (pathName === "/boat-builder/43-solstice/") {
              parameters.motor = "711"
            }
            if (pathName === "/boat-builder/52-vitesse/") {
              parameters.motor = "882"
            }
            if (pathName === "/boat-builder/60-pied-a-mer-2/") {
              parameters.motor = "841"
            }
          }
        }
        setMaterials(parameters)
      }
    }
  }, [])

  const handleAddSavedColor = requestedColor => {
    if (
      savedColors.length &&
      savedColors.find(color => color === requestedColor)
    ) {
      return
    }

    setSavedColors([...savedColors, requestedColor])
  }

  return (
    <ConfiguratorContext.Provider
      value={{
        savedColors,
        addColor: color => handleAddSavedColor(color),
        materials: materials,
        setMaterial: material =>
          setMaterials(materials => ({ ...materials, ...material })),
        shareableQueryString,
      }}
    >
      {props.children}
    </ConfiguratorContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
