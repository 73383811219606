import { createTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createTheme({
  navHeight: 59,
  sectionPadding: "padding-top: 2.5em; padding-bottom: 2.5em;",
  fonts: {
    primaryFont: "Open Sans, sans-serif",
    secondaryFont: "Syncopate, sans-serif",
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#2A334E",
      light: "#2A334E",
      dark: "#2A334E",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#DF272F",
      light: "#DF272F",
      dark: "#DF272F",
    },
    tertiary: {
      main: "#52D6FF",
    },
    text: {
      primary: "#404040",
      secondary: "#404040",
      disabled: "#10355A",
      hint: "#10355A",
    },
  },
  typography: {
    fontFamily: `"Open Sans", "sans-serif", "Arial", sans-serif`,
  },
})
export default theme
